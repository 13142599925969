<template>
  <div>
    <v-row class="pt-1">
      <v-col cols="12" sm="6" md="6">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <v-card-title>
            <v-icon
              :color="checking ? 'red lighten-2' : 'indigo'"
              class="mr-12"
              size="64"
              @click="takePulse"
            >
              mdi-monitor-multiple
            </v-icon>
            <v-row align="start">
              <div class="text-caption grey--text text-uppercase">
                服务器负载
              </div>
              <div>
                <span
                  class="text-h3 font-weight-black"
                  v-text="avg || '—'"
                ></span>
                <strong v-if="avg">tps</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>

            <!-- <v-btn icon class="align-self-start" size="28">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn> -->
          </v-card-title>

          <v-sheet color="transparent">
            <v-sparkline
              :fill="fill"
              :gradient="selectedGradient"
              :line-width="width"
              :padding="padding"
              :smooth="radius || false"
              :value="value"
              auto-draw
            ></v-sparkline>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <v-card-title>
            <v-icon
              :color="checking ? 'red lighten-2' : 'indigo'"
              class="mr-12"
              size="64"
              @click="takePulse"
            >
              mdi-account-check
            </v-icon>
            <v-row align="start">
              <div class="text-caption grey--text text-uppercase">
                资产访问频率
              </div>
              <div>
                <span
                  class="text-h3 font-weight-black"
                  v-text="avg1 || '—'"
                ></span>
                <strong v-if="avg1">次/s</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-sheet color="transparent">
            <v-sparkline
              :key="String(avg1)"
              :smooth="16"
              :gradient="['#f72047', '#ffd200', '#1feaea']"
              :line-width="3"
              :value="heartbeats1"
              auto-draw
              stroke-linecap="round"
            ></v-sparkline>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pt-1">
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">资产</div>
              <v-list-item-title class="text-h5 mb-1">
                25/100
              </v-list-item-title>
              <v-list-item-subtitle>今日新增 1</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><img
                class="pa-0"
                src="@/assets/image/domain/asset.png"
                alt="guof"
            /></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> 查看详情 </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">用户</div>
              <v-list-item-title class="text-h5 mb-1">
                80/100
              </v-list-item-title>
              <v-list-item-subtitle>今日新增 5</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><img
                class="pa-0"
                src="@/assets/image/domain/user.png"
                alt="guof"
            /></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> 查看详情 </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">地址资源池</div>
              <v-list-item-title class="text-h5 mb-1">
                298/1000
              </v-list-item-title>
              <v-list-item-subtitle>今日新增 30</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><img class="pa-0" src="@/assets/image/domain/ip.png" alt="guof"
            /></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> 查看详情 </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">规则映射清单</div>
              <v-list-item-title class="text-h5 mb-1">
                150/1000
              </v-list-item-title>
              <v-list-item-subtitle>今日新增 18</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey"
              ><img
                class="pa-0"
                src="@/assets/image/domain/rule.png"
                alt="guof"
            /></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> 查看详情 </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];
const exhale = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default {
  components: {},
  name: "covid19api",
  data() {
    return {
      search: "",
      fill: true,
      selectedGradient: gradients[4],
      gradients,
      padding: 8,
      radius: 10,
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      width: 2,
      checking: false,
      heartbeats: [],
      heartbeats1: [],
    };
  },
  computed: {
    avg() {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0);
      const length = this.heartbeats.length;

      if (!sum && !length) return 0;

      return Math.ceil(sum / length);
    },
    avg1() {
      const sum = this.heartbeats1.reduce((acc, cur) => acc + cur, 0);
      const length = this.heartbeats1.length;

      if (!sum && !length) return 0;

      return Math.ceil(sum / length);
    },
  },
  methods: {
    ...mapMutations(["setPageTitle"]),
    heartbeat() {
      return Math.ceil(Math.random() * (120 - 80) + 80);
    },
    heartbeat1() {
      return Math.ceil(Math.random() * (120 - 80) + 80);
    },
    async takePulse(inhale = true) {
      this.checking = true;

      inhale && (await exhale(1000));

      this.heartbeats = Array.from({ length: 20 }, this.heartbeat);
      this.heartbeats1 = Array.from({ length: 20 }, this.heartbeat1);

      this.checking = false;
    },
    auth(){
      let username=localStorage.getItem("username");
      let id=localStorage.getItem("id");
      let token=localStorage.getItem("token");
      if(username==null||id==null||token==null){
        this.$router.push({
          path:"/login"
        })
      }
      if(token){
        let data ={
          token:token,
        }
        this.$axios.post("/trunkserver/accounts/api-verify_token/",data)
        .then((res)=>{
          if(res.status==200){

          }else{
             this.$router.push({
                path:"/login"
              })
          }
        }).catch((err)=>{
           this.$router.push({
                path:"/login"
              })
        })
      }
    }
  },
  created() {
    this.setPageTitle("Dashboard");
    this.takePulse(false);
    this.auth();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
